<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="code" label="Code / ID" v-model="code"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('code')">{{errors.first('code')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <small class="ml-2">Car Factory</small>
          <v-select v-model="car_factory_id" :options="car_factories" name="car_factory_id" label="name" :reduce="e => e.id"></v-select>
          <!-- <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="name"></vs-input> -->
          <span class="text-danger text-sm" v-show="errors.has('car_factory_id')">{{errors.first('car_factory_id')}}</span>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import vSelect from 'vue-select'

export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  components:{
    vSelect
  },
  data(){
    return {
      code: "",
      name: "",
      car_factory_id: ""
    }
  },
  computed:{
    ...mapState({
      car_factories: state => state.car_factories.all
    })
  },
  methods:{
    ...mapActions({
      dispatchStore: 'car_types/store',
      dispatchUpdate: 'car_types/update',
      dispatchShow: 'car_types/show',
      dispatchAllCarFactory: 'car_factories/all',
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          code: this.code,
          name: this.name,
          car_factory_id: this.car_factory_id
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          if(error.status == 422) {
              for(let x in error.data) {
                  this.$vs.notify({
                    title: 'Oops!',
                    text: error.data[x][0],
                    color: 'danger'
                  })
                  break
              }
          } else {
              this.$vs.notify({
                title: 'Oops!',
                text: error.data.message,
                color: 'danger'
              })
          }
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.code = data.code
      this.name = data.name
      this.car_factory_id = data.car_factory_id
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    }
    this.dispatchAllCarFactory();
  }
}
</script>

<style>

</style>